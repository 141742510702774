import React from 'react'
import styled, { css } from 'styled-components'

import { H, Image, P, Wrapper } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'
import { getWidthOfContainerColumns } from '@farewill/ui/helpers/layout'
import { screenMin } from '@farewill/ui/helpers/responsive'

import PreventOrphan from 'components/PreventOrphan'

const StyledImageLeft = styled(Image)<{
  $bottom: string
}>`
  display: none;

  ${screenMin.l`
    display: block;
    position: relative;
    pointer-events: none;
    width: 100%;
  `};

  ${({ $bottom }) =>
    css`
      ${screenMin.l`
        bottom: ${$bottom || '-25%'};
      `}
    `}
`

const StyledImageRight = styled(Image)<{
  $top: string
}>`
  display: none;

  ${screenMin.l`
    display: block;
    position: relative;
    pointer-events: none;
    width: 100%;
  `}

  ${({ $top }) =>
    css`
      ${screenMin.l`
        top: ${$top || '10%'};
      `}
    `}
`
const StyledImageWrapper = styled(Wrapper)`
  ${screenMin.m`
    display: none;
    pointer-events: none;
  `};
`
const StyledMobileImage = styled(Image)`
  ${screenMin.m`
    display: none;
    pointer-events: none;
  `};
`

const StyledWrapper = styled(Wrapper)`
  flex-direction: column;
  text-align: left;

  ${screenMin.m`
    display: flex;
    flex-direction: row;
    justify-content: center;
  `};
`

const TextWrapper = styled(Wrapper)<{ maxWidth: number }>`
  margin: 0;

  text-align: left;

  ${screenMin.m`
    text-align: center;
  `};

  ${screenMin.l`
    margin: 0 ${GTR.S};
  `};
`

interface HeroWithImagesProps {
  title: React.ReactNode
  subtitle: string
  images: {
    imageLeft: string
    imageRight: string
    mobileImage: string
  }
  imageLeftBottom?: string
  imageRightTop?: string
  additionalContent: React.ReactNode
  maxTitleWidthInColumns?: number
  maxSubtitleWidthInColumns?: number
}

const HeroWithImages = ({
  title,
  subtitle,
  additionalContent = null,
  images,
  imageLeftBottom,
  imageRightTop,
  maxTitleWidthInColumns = 6,
  maxSubtitleWidthInColumns = 6,
}: HeroWithImagesProps): React.ReactElement => (
  <>
    <StyledWrapper centered container>
      <StyledImageLeft
        path={images.imageLeft}
        $bottom={imageLeftBottom}
        loading="eager"
        width={270}
      />
      <TextWrapper maxWidthInColumns={maxTitleWidthInColumns}>
        <H tag="h1" size="XL" decorative margin={[0, 'auto']}>
          {title}
        </H>
        <P
          size="L"
          margin={['S', 0, 'M']}
          marginFromM={['S', 'auto', 'M']}
          color={COLOR.GREY.DARK}
          maxWidth={getWidthOfContainerColumns(maxSubtitleWidthInColumns, 'L')}
        >
          <PreventOrphan minimumWords={3}>{subtitle}</PreventOrphan>
        </P>
        {additionalContent}
      </TextWrapper>
      <StyledImageWrapper container centered>
        <StyledMobileImage
          loading="eager"
          path={images.mobileImage}
          width={500}
          stretch
        />
      </StyledImageWrapper>
      <StyledImageRight
        loading="eager"
        path={images.imageRight}
        $top={imageRightTop}
        width={300}
      />
    </StyledWrapper>
  </>
)

export default HeroWithImages
