import React from 'react'
import styled from 'styled-components'
import { P, Wrapper } from '@farewill/ui'

import HeroWithImages from 'components/HeroWithImages'
import AccoladesImage from 'components/AccoladesImage'
import QuoteButton from 'views/probate/components/QuoteButton'

import data from './data'

const StyledSpan = styled.span`
  white-space: nowrap;
`

interface SliceHeroProps {
  ctaText?: string
  title?: string
  subtitle?: string
}

const Hero = ({
  ctaText,
  title,
  subtitle,
}: SliceHeroProps): React.ReactElement | null => (
  <Wrapper paddingFromXL={['M', 0]}>
    <HeroWithImages
      title={
        title || (
          <>
            Award-winning, <StyledSpan>low-cost</StyledSpan> probate
          </>
        )
      }
      subtitle={subtitle || data.subtitle}
      images={data.images}
      imageRightTop="20%"
      additionalContent={
        <>
          <Wrapper>
            <QuoteButton buttonText={ctaText} boldText />
          </Wrapper>

          <Wrapper margin={['M', 0, 0]} marginFromL={['XL', 0, 0]} centered>
            <AccoladesImage />
            <Wrapper centered>
              <P size="S" margin={['S', 0, 0]} strong>
                4 x winner Wills & Probate Firm of the Year
              </P>
              <P size="S" strong>
                UK Enterprise Customer Care Excellence Award 2024
              </P>
            </Wrapper>
          </Wrapper>
        </>
      }
    />
  </Wrapper>
)

export default Hero
